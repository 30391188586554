@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
/* Variables */

*, *:before, *:after {
    box-sizing: inherit;
    font-family: 'Lato', sans-serif;
}

body {
    font-size: 1rem;
    line-height: 1.5;
    background-image: url('/img/backgound_4.jpg');
    background-size: cover;
    background-position: top center;
    /* background-attachment: fixed; */
}

.content {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    height: 100vh;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
}

.content-form {
    max-width: 600px;
    width: 80%;
    padding: 0 0px 40px 0px;
    margin: 0 auto;
    background-color: white;
    border-radius: 5px;
    text-align: center;
    min-height: 50%;
    color: #555;
    -webkit-box-shadow: 0px 3px 21px 0px rgba(50, 50, 50, 0.21);
    -moz-box-shadow: 0px 3px 21px 0px rgba(50, 50, 50, 0.21);
    box-shadow: 0px 3px 21px 0px rgba(50, 50, 50, 0.21)
}

.header {
    padding: 40px;
    /* width: 100%; */
    border-radius: 5px 5px 0 0;
    background-color: rgb(195 137 8);
}

.title {
    margin-top: 30px;
    font-size: 24px;
    font-family: 'DM Serif Display', serif;
    color: #fff
}

.body {
    padding: 30px;
    position: relative;
    min-height: 300px;
}

.verse-form {
    display: block;
}

.header>img {
    width: 200px;
}

label[for="email"] {
    text-align: left;
}

input#email {
    padding: 20px;
    margin-top: 5px;
    background-color: white;
    width: calc(100% - 50px);
    border-radius: 5px;
    border: 1px solid #ddd;
    outline: none;
}

input#email::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #bbb;
    opacity: 1;
    /* Firefox */
}

input.error {
    border: 1px solid red!important;
}

#email-error {
    visibility: hidden;
    color: red;
    text-align: left;
}

/* Checkbox */

/* The container */

.agreement_checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-top: 25px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: left;
}

.agreement_checkbox>div {
    line-height: 0.9;
}

.agreement_checkbox>div:nth-child(2) {
    padding-top: 10px;
}

/* Hide the browser's default checkbox */

.agreement_checkbox input {
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: white;
    border: 1px solid #bbb;
    border-radius: 3px;
    transition: .5s;
}

/* On mouse-over, add a grey background color */

.agreement_checkbox:hover input~.checkmark {
    background-color: #eee;
}

/* When the checkbox is checked, add a blue background */

.agreement_checkbox input:checked~.checkmark {
    background-color: #0088cc;
    border: 1px solid #0088cc;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */

.agreement_checkbox input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */

.agreement_checkbox .checkmark:after {
    left: 6px;
    top: 2px;
    width: 7px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.getVerse {
    background-color: #cc5400;
    border: 0px;
    padding: 20px 0;
    width: 100%;
    color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    font-weight: 700;
    margin-top: 20px;
    cursor: pointer;
    transition: .5s;
    font-size: 16px;
}

.hiddenVerseHolder {
    height: 0;
    visibility: hidden;
}

.getVerse:hover {
    color: white;
}

.verse {
    display: none;
    font-size: 16px!important;
    line-height: 1;
}

.verse a {
    color: #0088cc;
    font-size: 16px;
    display: block;
    position: absolute;
    bottom: 10;
    left: 0;
    right: 0;
}

.verse-fr p, .verse-en p {
    color: #0088cc;
    font-weight: 700;
}

@media screen and (max-width: 1060px) {
    .content-form {
        width: 100%;
    }
    .body {
        padding: 30px;
        min-height: 350px;
    }
}

@media screen and (max-width: 600px) {
    *{
        font-size: 14px;
    }
    .content {
        height: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }
    .body {
        padding: 20px;
    }
    .header>img {
        width: 150px;
    }
    .header {
        padding: 20px;
        /* width: 100%; */
        border-radius: 5px 5px 0 0;
        /*background-color: rgba(252, 248, 227);*/
        background-color: rgba(195, 137, 8, 1);
    }
    .title {
        margin-top: 10px;
        font-size: 16px;
    }
    .getVerse {
        margin-top: 10px;
        font-size: 14px;
        padding: 10px 0;
    }
    .checkmark {
        height: 15px;
        width: 15px;
    }
    .agreement_checkbox .checkmark:after {
        left: 4px;
        top: 1px;
        width: 5px;
        height: 7px;
    }
}